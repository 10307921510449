import { Container, Grid } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import React, { useState } from "react"
import axios from "axios"
import FormData from "form-data"
import { useFormik } from "formik"
import Bg from "../images/svgs/BG.svg"
import ScrollAnimation from "react-animate-on-scroll"
import { navigate } from "gatsby"

const ContactFormSection = ({ data }) => {
  const [submitMsg, setSubmitMsg] = useState("Submit")
  const [loading, setLoading] = React.useState(false)
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    },
    validate,
    onSubmit: (values) => {
      submitForm(values)
    },
  })

  const submitForm = (values) => {
    setLoading(true)
    const contactData = new FormData()
    contactData.append("fname", values.firstName)
    contactData.append("lname", values.lastName)
    contactData.append("email", values.email)
    contactData.append("phone", values.phone)
    contactData.append("msg", values.message)
    console.log(values)
    let config = {
      method: "post",
      url: "https://wp.jandakotheli-co.com.au/wp-json/contact-form-7/v1/contact-forms/7/feedback",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: contactData,
    }

    axios(config)
      .then(function (response) {
        setTimeout(function () {
          setLoading(false)
          setSubmitMsg("Sent")
          setTimeout(function () {
            navigate("/thank-you/")
          }, 500)
        }, 1500)
      })
      .catch(function (error) {
        console.log(error)
        setTimeout(function () {
          setLoading(false)
          setSubmitMsg("Try Again")
        }, 1500)
      })
  }

  return (
    <section className="contactForm">
      <Bg className="bgSvg1" />
      <Bg className="bgSvg2" />
      <Container>
        <ScrollAnimation animateIn="animate__fadeIn">
          <h2>Get In Touch</h2>
        </ScrollAnimation>
        <div className="formContainer">
          <ScrollAnimation animateIn="animate__fadeIn" delay={200}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={4}>
                <Grid item md={6}>
                  <input
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />
                  <span className="error">
                    {formik.errors.firstName && formik.touched.firstName
                      ? `* ${formik.errors.firstName}`
                      : null}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <input
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                  <span className="error">
                    {formik.errors.lastName && formik.touched.lastName
                      ? `* ${formik.errors.lastName}`
                      : null}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <span className="error">
                    {formik.errors.email && formik.touched.email
                      ? `* ${formik.errors.email}`
                      : null}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <input
                    id="phone"
                    name="phone"
                    type="phone"
                    placeholder="Phone Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  <span className="error">
                    {formik.errors.phone && formik.touched.phone
                      ? `* ${formik.errors.phone}`
                      : null}
                  </span>
                </Grid>
                <Grid item md={12}>
                  <textarea
                    rows="5"
                    id="message"
                    name="message"
                    type="message"
                    placeholder="Message"
                    onChange={formik.handleChange}
                    value={formik.values.message}
                  />
                </Grid>
              </Grid>

              <LoadingButton
                variant="contained"
                size="large"
                loading={loading}
                className={` ${submitMsg === "Sent" ? "success" : ""} ${
                  submitMsg === "Try Again" ? "fail" : ""
                }  `}
                type="submit"
              >
                {submitMsg}
              </LoadingButton>
            </form>
          </ScrollAnimation>
        </div>
      </Container>
    </section>
  )
}

export default ContactFormSection

const validate = (values) => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = "Required"
  } else if (values.firstName.length > 15) {
    errors.firstName = "Must be 15 characters or less"
  }

  if (!values.lastName) {
    errors.lastName = "Required"
  } else if (values.lastName.length > 20) {
    errors.lastName = "Must be 20 characters or less"
  }

  if (!values.email) {
    errors.email = "Required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }

  if (!values.phone) {
    errors.phone = "Required"
  }
  // } else if (!/^[0-9]{10}$/i.test(values.phone)) {
  //   errors.phone = "Invalid phone number"
  // }

  return errors
}
